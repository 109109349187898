import Cookies from 'js-cookie';
import STATUS from './globalStatuses';

const statusTemplate = {
  getStatus: STATUS.UNFETCHED,
  modifyStatus: STATUS.UNFETCHED,
  errorData: null,
};

export default {
  cas: {
    ssoAttempts: 0,
    casStatus: statusTemplate,
  },
  csrf: {
    // Actual token stored in cookie, to avoid multi tab browsing issues
    status: !Cookies.get('CSRF-Token') ? STATUS.UNFETCHED : STATUS.FETCHED,
  },
  person: {
    person: {},
    names:
    {
      officialName: {
        firstName: '',
        lastName: '',
        middleName: '',
        suffix: '',
      },
    },
    name: {},
    employments: [],
    information: {
      externalSystemIds: {},
    },
    meta: {
      externalSystemIds: {},
    },
    genericInfo: [],
    identityVerify: [],
    workflowInstanceId: '',
    personETag: '',
    namesETag: '',
    nameETag: '',
    workflowETag: '',
    informationETag: '',
    genericInfoETag: '',

    namesStatus: statusTemplate,
    nameStatus: statusTemplate,
    personStatus: statusTemplate,
    employmentsStatus: statusTemplate,
    workflowInstanceIdStatus: statusTemplate,
    personInfoStatus: statusTemplate,
    personMetaStatus: statusTemplate,
    multistepCreateAccountStatus: statusTemplate,
    personGenericInfoStatus: statusTemplate,
  },
  creditsInfo: {
    creditsInfo: [],
    creditsInfoStatus: statusTemplate,
  },
  associations: {
    associations: {},
    personAssociationsStatus: statusTemplate,
  },
  citizenship: {
    citizenship: {},
    citizenshipETag: '',
    personCitizenshipStatus: statusTemplate,
  },
  certificate: {
    certificate: [],
    personCertificateStatus: statusTemplate,
  },
  military: {
    military: {},
    militaryETag: '',
    personMilitaryStatus: statusTemplate,
  },
  higherEds: {
    higherEds: [],
    higherEdsStatus: statusTemplate,
    esignHigherEds: [],
    esignHigherEdsStatus: statusTemplate,
  },
  secondaryEd: {
    secondaryEd: {},
    secondaryEdStatus: statusTemplate,
    secondaryEdOtherByDistrict: {},
    secondaryEdOtherByDistrictStatus: statusTemplate,
  },
  contact: {
    address: [],
    email: [{ emailAddress: '' }],
    phone: [{ phoneNumber: '' }],
    phoneConsent: {},
    emailConsent: {},
    addressETag: '',
    emailETag: '',
    phoneETag: '',
    phoneConsentETag: '',
    emailConsentETag: '',

    addressStatus: statusTemplate,
    emailStatus: statusTemplate,
    phoneStatus: statusTemplate,
    phoneConsentStatus: statusTemplate,
    emailConsentStatus: statusTemplate,
  },
  user: {
    user: {},
    userETag: '',

    userStatus: statusTemplate,
    tokenStatus: statusTemplate,
    token: {},
  },
  application: {
    application: {
      navigation: {
        pages: [],
      },
    },
    navigation: {
    },
    attachment: {
    },
    applicationETag: '',
    suppressAdmissionLicenseInfoPage: false,
    suppressAdmissionExpulsionInfoPage: false,
    suppressAdmissionTranscriptPage: false,
    suppressFinancialGrantsApplication: false,
    suppressFinancialLoanApplication: false,
    suppressFinancialMilitaryApplication: false,
    suppressFinancialGradPlusLoanApplication: false,
    suppressFinancialParentPlusLoanApplication: false,
    applicationStatus: statusTemplate,
    navigationStatus: statusTemplate,
    applicationTypeStatus: statusTemplate,
  },
  enumReducer: {
    milServiceTypes: [],
    milBranchTypes: [],
    secInstTypes: [],
    degreeTypes: [],
    milEducTypes: [],
    testProgTypes: [],

    countries: [],
    militaryCountries: [],
    states: {
      statesUS: [],
    },
    districts: {
      districtsUS: [],
    },
    provinces: {
      provincesMexico: [],
      provincesCanada: [],
    },
    administrativeTerritories: {
      administrativeTerritoriesUS: [],
    },
    outlyingAreas: {
      outlyingAreasUS: [],
    },
    militaryStates: {
      militaryStatesUS: [],
    },
    militaryCities: {
      militaryCitiesUS: [],
    },

    milServiceTypesStatus: statusTemplate,
    milBranchTypesStatus: statusTemplate,
    secInstTypesStatus: statusTemplate,
    degreeTypesStatus: statusTemplate,
    milEducTypesStatus: statusTemplate,
    testProgTypesStatus: statusTemplate,
    visaTypesStatus: statusTemplate,

    countriesStatus: statusTemplate,
    statesStatus: statusTemplate,
    districtsStatus: statusTemplate,
    provincesStatus: statusTemplate,
    administrativeTerritoriesStatus: statusTemplate,
    outlyingAreasStatus: statusTemplate,
    militaryCountriesStatus: statusTemplate,
    militaryStatesStatus: statusTemplate,
    militaryCitiesStatus: statusTemplate,
  },
  wallet: {
    ssn: '',
    creditBalance: {},
    ssnStatus: statusTemplate,
    creditBalanceStatus: statusTemplate,
  },
  programOfferingSearch: {
    programOffering: [],
    programOfferingDates: [],
    programOfferingStatus: statusTemplate,
    programOfferingDatesStatus: statusTemplate,
    programOfferingItem: {},
    programOfferingItemStatus: statusTemplate,
    programTemplateMap: {},
  },
  document: {
    dgspPacketsSupportedStatus: STATUS.UNFETCHED,
    dgspPacketsSupported: false,
    agreementDocs: [],
    agreementDocsStatus: 'AGREEMENT_DOCS_UNFETCHED',
    generalAgreementDocs: [],
    generalAgreementDocsStatus: 'GENERAL_AGREEMENT_DOCS_UNFETCHED',
    agreementPackage: {},
    agreementPackageStatus: statusTemplate,
    transcriptDocument: null,
    getAgreementSign: false,
    getAgreementSignStatus: statusTemplate,
    postAgreementSign: false,
    postAgreementSignStatus: 'POST_AGREEMENT_UNFETCHED',
    criminalConvictionDoc: {},
    criminalConvictionDocStatus: statusTemplate,
    newStudentChecklistDoc: {},
    newStudentChecklistDocStatus: statusTemplate,
    maAcknowledgementDoc: {},
    maAcknowledgementStatus: statusTemplate,
    uploadDocumentsToSATStatus: 'UPLOAD_DOCUMENTS_SAT_UNFETCHED',
    uploadDocumentsToEXPStatus: 'UPLOAD_DOCUMENTS_EXP_UNFETCHED',
    caAcknowledgementPfsDoc: {},
    caAcknowledgementPfsStatus: statusTemplate,
    postSignDocument: false,
    postSignDocumentStatus: statusTemplate,
    getReenrollDoc: false,
    getReenrollDocStatus: statusTemplate,
    esignTranscriptTask: {},
    esignTranscriptTaskStatus: statusTemplate,
    financialPlanSummary: {},
    financialPlanSummaryStatus: statusTemplate,
    financialPlanSummaryDownloaded: false,
    financialPlanSummaryDownloadedStatus: statusTemplate,
    postEsigningUrl: null,
    postEsigningUrlStatus: statusTemplate,
  },
  task: {
    tasks: [],
    workflow: {
      tasks: [],
    },
    isStaticContent: false,
    taskStatus: statusTemplate,
    workflowStatus: statusTemplate,
    detailedWorkflowStatus: {
      ACCOUNT_CREATION: statusTemplate,
      CONTACT_INFO: statusTemplate,
      TELL_US_MORE: statusTemplate,
      MILITARY: statusTemplate,
      PROGRAM_SELECTION: statusTemplate,
      LICENSE_INFO: statusTemplate,
      SECONDARY: statusTemplate,
      HIGHER: statusTemplate,
      EXPULSION_INFO: statusTemplate,
      CREDITS: statusTemplate,
      WORK: statusTemplate,
      FINANCIAL: statusTemplate,
      FINANCIAL_MILITARY: statusTemplate,
      FINANCIAL_GRANTS: statusTemplate,
      FINANCIAL_EMPLOYER_BENEFITS: statusTemplate,
      FINANCIAL_DISCOUNTS: statusTemplate,
      FINANCIAL_SCHOLARSHIPS: statusTemplate,
      FINANCIAL_TRIBAL_BENEFITS: statusTemplate,
      FINANCIAL_BENEFITS: statusTemplate,
      FINANCIAL_PERSONAL: statusTemplate,
      FINANCIAL_LOANS: statusTemplate,
      FINANCIAL_SUMMARY: statusTemplate,
      FINANCIAL_SUMMARY_DOWNLOAD: statusTemplate,
      MANAGE_FUNDS_CREDIT_BALANCE: statusTemplate,
      REVIEW: statusTemplate,
      REVIEW_ACKNOWLEDGE: statusTemplate,
      REVIEW_SIGN_SUBMIT: statusTemplate,
    },
  },
  personTasks: {
    personTasks: [],
    personTasksStatus: statusTemplate,
  },
  genesys: {
    chatState: null,
    chatEventListenerSet: false,
    cobrowseState: null,
    cobrowseEventListenerSet: false,
    textState: null,
    textEventListenerSet: false,
  },
  calculatorInput: {
    results: {
      id: {},
      personId: {},
      inputContext: {},
      aidYear: {},
      dependencyStatus: {},
      softDeleteEnabled: {},
      dateOfBirth: {},
      anticipatedStartDate: {},
      selectedFinCampus: {},
      dirtyFlag: {},
      curHashValue: {},
      prevHashValue: {},
      skipFinancialPlan: {},
      isIndirectExpenseIncluded: {},
      appFee: {},
      modDate: {},
      taxAddress: {},
      academicProgramOfferings: {},
      deductions: {},
      discounts: {},
      indirectExpenses: {},
      benefits: {},
      contributions: {},
      military: {},
    },
    calculatorInputStatus: statusTemplate,
  },
  calculatorSummary: {
    results: {
      detail: {
        value: {
          yearOneEstimates: {
            costOfAttendance: {
              inDirectExpenses: [],
              directExpenses: [],
            },
          },
          program: {
            summary: {},
          },
          benefits: {},
          totalAcademicMonths: {},
          contributions: [],
          loanRepayment: {},
        },
      },
    },
    calculatorSummaryStatus: {
      ...statusTemplate,
      initialLoadStatus: STATUS.UNFETCHED,
    },
  },
  militaryBenefits: {
    primary: {
      results: {},
      militaryBenefitsStatus: statusTemplate,
    },
    secondary: {
      results: {},
      militaryBenefitsStatus: statusTemplate,
    },
  },
  preferences: {
    appExperience: {},
    appExperienceStatus: statusTemplate,
    featureFlags: {},
    featureFlagsStatus: statusTemplate,
    choosePath: {
      id: '',
      value: {
        militarySelected: '',
        federalGrantsSelected: '',
        employerBenefitsSelected: '',
        employerDiscountsSelected: '',
        scholarshipsSelected: '',
        tribalBenefitsSelected: '',
        noneApplySelected: '',
        showLivingExpenses: '',
      },
      choosePathStatus: statusTemplate,
    },
  },
  student: {
    programMembershipStatus: statusTemplate,
    studentStatusStatus: statusTemplate,
  },
  forgerock: {
    forgerockStatus: statusTemplate,
  },
  employerDiscounts: {
    employerDiscount: {},
    employerDiscountStatus: statusTemplate,
    employerDiscountsStatus: statusTemplate,
  },
  employerBenefits: {
    employerBenefits: {},
    employerBenefitsStatus: statusTemplate,
  },
  leads: {
    savedPrograms: {},
    savedProgramsStatus: statusTemplate,
  },
  identityVerify: {
    identityVerificationStatus: '',
    identityVerifyStatus: statusTemplate,
  },
};
