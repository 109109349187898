/* eslint-disable no-underscore-dangle */
import FlagNames from './featureFlags';

/*
  NOTE:
  Do not use defaultFeatureFlags directly and instead use
  getFeatureFlag from featureFlagHelper.js. That method pulls
  from the redux store and allows us to override them at runtime.

  Add any new flag names to the import file above
  Make sure to follow the pattern below as env vars are
  strings so anything but true will be considered false
*/

// const splitCheck = flagName => (window.__ENV[flagName] === undefined
//                       || window.__ENV[flagName] === '1');

export default {
  [FlagNames.ONBOARDING_ROUTING]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_ONBOARDING_ROUTING === 'true',
  [FlagNames.ONBOARDING_DYNAMIC]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_ONBOARDING_DYNAMIC === 'true',
  [FlagNames.ADMISSIONS_TRANSCRIPT_ROUTING]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_TRANSCRIPT_ROUTING === 'true',
  [FlagNames.PROGRAM_ONLINE_ONLY]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_PROGRAM_ONLINE_ONLY === 'true',
  [FlagNames.GENESYS_CHAT_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_GENESYS_CHAT_ENABLED === 'true',
  [FlagNames.GENESYS_COBROWSE_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_GENESYS_COBROWSE_ENABLED === 'true',
  [FlagNames.GENESYS_TEXT_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_GENESYS_TEXT_ENABLED === 'true',
  [FlagNames.QUICKAPP_PERSON_IMPORT_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_QUICKAPP_PERSON_IMPORT_ENABLED === 'true',
  [FlagNames.BRIDGE_PROGRAM_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_BRIDGE_PROGRAM_ENABLED === 'true',
  [FlagNames.QUICKAPP_FORCE_LOGOUT]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_QUICKAPP_FORCE_LOGOUT === 'true',
  [FlagNames.INVITE_TO_APPLY_PROGR_EXCLUDE_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_INVITE_TO_APPLY_PROGR_EXCLUDE_ENABLED === 'true',
  [FlagNames.BLACKBOARD_COLLABORATION_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_BLACKBOARD_COLLABORATION_ENABLED === 'true',
  [FlagNames.MAINTENANCE_PAGE_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_MAINTENANCE_PAGE_ENABLED === 'true',
  [FlagNames.ENABLE_SAGAS_CANARY_DEPLOYMENT]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_ENABLE_SAGAS_CANARY_DEPLOYMENT === 'true',
  [FlagNames.ENABLE_POST_SUB_TASKS_MODAL]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_ENABLE_POST_SUB_TASKS_MODAL === 'true',
  [FlagNames.ESIGN_CALLBACK_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_ESIGN_CALLBACK_ENABLED === 'true',
  [FlagNames.NAVIGATION_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_NAVIGATION_ENABLED === 'true',
  [FlagNames.REENROLL_REENTRY_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_REENROLL_REENTRY_ENABLED === 'true',
  [FlagNames.ALUMNI_ONLY_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_ALUMNI_ONLY_ENABLED === 'true',
  [FlagNames.SAGA_REDIRECT_MODAL_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_SAGA_REDIRECT_MODAL_ENABLED === 'true',
  [FlagNames.TAPCAP_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_TAPCAP_ENABLED === 'true',
  [FlagNames.SCLP_TEST_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_SCLP_TEST_ENABLED === 'true',
  [FlagNames.LOAN_AND_CONTRIBUTION_MERGE_TEST_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_LOAN_AND_CONTRIBUTION_MERGE_TEST_ENABLED === 'true',
  [FlagNames.REENROLL_ONLINE_ONLY_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_REENROLL_ONLINE_ONLY_ENABLED === 'true',
  [FlagNames.REENROLL_TEMPLATE_MS_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_REENROLL_TEMPLATE_MS_ENABLED === 'true',
  [FlagNames.COGNITO_VALIDATION_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_COGNITO_VALIDATION_ENABLED === 'true',
  [FlagNames.REMOVE_PUB_CLIENT_WALKIN_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_REMOVE_PUB_CLIENT_WALKIN_ENABLED === 'true',
  [FlagNames.CONTACT_V2_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_CONTACT_V2_ENABLED === 'true',
  [FlagNames.PROGRAM_CHANGE_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_PROGRAM_CHANGE_ENABLED === 'true',
  [FlagNames.ACKNOWLEDGEMENTS_TEST_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_ACKNOWLEDGEMENTS_TEST_ENABLED === 'true',
  [FlagNames.ENABLE_APP_EXPIRATION]:
    (window.__ENV || {}).REACT_APP_ENABLE_APP_EXPIRATION === 'true',
  [FlagNames.ENABLE_ALLOY_EVENT]:
    (window.__ENV || {}).REACT_APP_ENABLE_ALLOY_EVENT === 'true',
  [FlagNames.ENABLE_NAVIGATION_SWITCH]:
    (window.__ENV || {}).REACT_APP_ENABLE_NAVIGATION_SWITCH === 'true',
  [FlagNames.DGSP_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_DGSP_ENABLED === 'true',
  [FlagNames.NO_SSN_GRANTS_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_NO_SSN_GRANTS_ENABLED === 'true',
  [FlagNames.ENABLE_APP_STATUS_SYNC_SRM]:
    (window.__ENV || {}).REACT_APP_ENABLE_APP_STATUS_SYNC_SRM === 'true',
  [FlagNames.ENABLE_NEW_NAVIGATION]:
    (window.__ENV || {}).REACT_APP_ENABLE_NEW_NAVIGATION === 'true',
  [FlagNames.SHOW_EMPLOYER_DROPDOWN]:
    (window.__ENV || {}).REACT_APP_SHOW_EMPLOYER_DROPDOWN === 'true',
  [FlagNames.SHOW_EMPLOYER_BENEFIT_MSG]:
    (window.__ENV || {}).REACT_APP_SHOW_EMPLOYER_BENEFIT_MSG === 'true',
  [FlagNames.CYOP_REDESIGN_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_CYOP_REDESIGN_ENABLED === 'true',
  [FlagNames.ENABLE_ACPREPOP_COOKIE]:
    (window.__ENV || {}).REACT_APP_ENABLE_ACPREPOP_COOKIE === 'true',
  // Default is true only when the master flag is true and the split flag is not available
  // or split flag is set to 1
  [FlagNames.ENABLE_DASHBOARD_SWITCH]:
    (window.__ENV || {}).REACT_APP_ENABLE_DASHBOARD_SWITCH === 'true',
  [FlagNames.PROGRAMCHANGE_AFTER_REG]:
    (window.__ENV || {}).REACT_APP_PROGRAMCHANGE_AFTER_REG === 'true',
  [FlagNames.NAE_DASHBOARD_ENABLED]:
    (window.__ENV || {}).REACT_APP_NAE_DASHBOARD_ENABLED === 'true',
  [FlagNames.NAE_DASHBOARD_FIRST]:
    (window.__ENV || {}).REACT_APP_NAE_DASHBOARD_FIRST === 'true',
  [FlagNames.FORGEROCK_SSO_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_FORGEROCK_SSO_ENABLED === 'true',
  [FlagNames.UPLOAD_TRANSCRIPT_DOCUMENT]:
    (window.__ENV || {}).REACT_APP_UPLOAD_TRANSCRIPT_DOCUMENT === 'true',
  [FlagNames.MOBILE_ADA_AUTOCOMPLETE_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_MOBILE_ADA_AUTOCOMPLETE_ENABLED === 'true',
  [FlagNames.RECAPTCHA_ENABLED]:
    (window.__ENV || {}).REACT_APP_FEATURE_FLAG_RECAPTCHA_ENABLED === 'true',
  [FlagNames.SHOW_EMPLOYER_BORROW_MSG]:
  (window.__ENV || {}).REACT_APP_SHOW_EMPLOYER_BORROW_MSG === 'true',
  [FlagNames.AUTO_CHECK_EMPLOYER_ON_CYOP]:
  (window.__ENV || {}).REACT_APP_AUTO_CHECK_EMPLOYER_ON_CYOP === 'true',
  [FlagNames.ENABLE_SAI_CALCULATOR]:
  (window.__ENV || {}).REACT_APP_ENABLE_SAI_CALCULATOR === 'true',
  [FlagNames.ENABLE_ESI_INDICATOR]:
  (window.__ENV || {}).REACT_APP_ENABLE_ESI_INDICATOR === 'true',
  [FlagNames.ENABLE_DV_INTEGRATION]:
  (window.__ENV || {}).REACT_APP_ENABLE_DV_INTEGRATION === 'true',
  [FlagNames.ENABLE_SAVED_PROGRAM_INFO]:
  (window.__ENV || {}).REACT_APP_ENABLE_SAVED_PROGRAM_INFO === 'true',
  [FlagNames.ENABLE_IDENTITY_VERIFICATION]:
  (window.__ENV || {}).REACT_APP_ENABLE_IDENTITY_VERIFICATION === 'true',
  [FlagNames.IDENTITY_VERIFY_DESKTOP_ONLY]:
  (window.__ENV || {}).REACT_APP_IDENTITY_VERIFY_DESKTOP_ONLY === 'true',
  [FlagNames.IDENTITY_VERIFY_USE_SEGMENT]:
  (window.__ENV || {}).REACT_APP_FEATURE_FLAG_IDENTITY_VERIFY_USE_SEGMENT === 'true',
  [FlagNames.ADMISSIONS_IDENTITYVERIFY_ROUTING]:
    (window.__ENV || {}).REACT_APP_ADMISSIONS_IDENTITYVERIFY_ROUTING === 'true',
  [FlagNames.ENABLE_DUP_SSN_CHECK]:
  (window.__ENV || {}).REACT_APP_ENABLE_DUP_SSN_CHECK === 'true',
};
