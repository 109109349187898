import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './styles';

// =====================================================
// This component is USED with the 'Footnotes' component
// =====================================================

export default function InTextReference({
  referenceNumber,
  referenceText,
  handleClick,
  footnotesCount,
}) {
  const classes = styles();

  return (
    <a
      href={`#footnote${referenceNumber}`}
      aria-describedby={footnotesCount === ''
        ? 'footnote-label'
        : `footnote-label${footnotesCount}`}
      id={`footnoteRef${referenceNumber}`}
      className={footnotesCount === ''
        ? clsx(classes.reference, 'inTextReference')
        : clsx(classes.reference2, `inTextReference${footnotesCount}`)}
      onClick={() => {
        handleClick();
      }}
    >
      {referenceText}
    </a>
  );
}

InTextReference.defaultProps = {
  referenceNumber: 1,
  referenceText: '',
  handleClick: () => {},
  footnotesCount: '',
};

InTextReference.propTypes = {
  referenceNumber: PropTypes.number,
  referenceText: PropTypes.string,
  handleClick: PropTypes.func,
  footnotesCount: PropTypes.string,
};
