/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Typography, Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  ReactComponent as MobileIcon,
} from '../../modules/admissions/components/icons/mobile-phone96x96.svg';
import {
  ReactComponent as IdentificationIcon,
} from '../../modules/admissions/components/icons/id-identification96x96.svg';
import {
  ReactComponent as SelfieIcon,
} from '../../modules/admissions/components/icons/selfie96x96.svg';
import {
  ReactComponent as CheckIcon,
} from '../../modules/admissions/components/icons/check_active96x96.svg';
import styles from './styles';

export default function HowIdentityVerifyWorks({
  title,
  subTitle,
  stepOneHeader,
  paragraphOne,
  stepTwoHeader,
  paragraphTwo,
  stepThreeHeader,
  paragraphThree,
  stepFourHeader,
  paragraphFour,
  children,
}) {
  const classes = styles();

  return (
    <div className={clsx(classes.root, 'howIdentityVerifyWorks')}>
      <Typography variant="h2" className={classes.title}>{title}</Typography>
      <Typography className={classes.subTitle}>{subTitle}</Typography>
      <ol className={classes.orderedList}>
        <li>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.listItem}
          >
            <Grid
              item
              aria-hidden="true"
            >
              <MobileIcon />
            </Grid>
            <Grid item>
              <Typography variant="h3" className={classes.header}>
                {stepOneHeader}
              </Typography>
              <Typography className={classes.paragraph}>{paragraphOne}</Typography>
            </Grid>
          </Grid>
        </li>
        <li>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.listItem}
          >
            <Grid
              item
              aria-hidden="true"
            >
              <IdentificationIcon />
            </Grid>
            <Grid item>
              <Typography variant="h3" className={classes.header}>
                {stepTwoHeader}
              </Typography>
              <Typography className={classes.paragraph}>{paragraphTwo}</Typography>
            </Grid>
          </Grid>
        </li>
        {/* <li>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.listItem}
          >
            <Grid
              item
              aria-hidden="true"
            >
              <SelfieIcon />
            </Grid>
            <Grid item>
              <Typography variant="h3" className={classes.header}>
                {stepThreeHeader}
              </Typography>
              <Typography className={classes.paragraph}>{paragraphThree}</Typography>
            </Grid>
          </Grid>
        </li> */}
        <li>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.listItem}
          >
            <Grid
              item
              aria-hidden="true"
            >
              <CheckIcon />
            </Grid>
            <Grid item>
              <Typography variant="h3" className={classes.header}>
                {stepFourHeader}
              </Typography>
              <Typography className={classes.paragraph}>{paragraphFour}</Typography>
            </Grid>
          </Grid>
        </li>
      </ol>
      <div className={classes.childContainer}>
        {children}
      </div>
    </div>
  );
}

HowIdentityVerifyWorks.defaultProps = {
  children: null,
};

HowIdentityVerifyWorks.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  stepOneHeader: PropTypes.string.isRequired,
  paragraphOne: PropTypes.string.isRequired,
  stepTwoHeader: PropTypes.string.isRequired,
  paragraphTwo: PropTypes.string.isRequired,
  stepThreeHeader: PropTypes.string.isRequired,
  paragraphThree: PropTypes.string.isRequired,
  stepFourHeader: PropTypes.string.isRequired,
  paragraphFour: PropTypes.string.isRequired,
  children: PropTypes.element,
};
